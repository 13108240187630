import React , { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import io from 'socket.io-client';

const ChatRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();  // useLocation을 먼저 호출합니다.
  const { roomId, name, auth, title } = location.state || {};
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const localStreamRef = useRef();
  const userStreamRefs = useRef({});
  const peerConnections = useRef({});
  const dataChannels = useRef({});
  const [userAudioStatus, setUserAudioStatus] = useState({});
  const [microphoneStatus, setMicrophoneStatus] = useState(true);
  const [userChatStatus, setUserChatStatus] = useState({});
  const [userId, setUserId] = useState('');
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null); // ref 생성

  const getUrl = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const host = isDevelopment ? process.env.REACT_APP_API_HOST : window.location.hostname;
    const port = isDevelopment ? process.env.REACT_APP_API_PORT : (window.location.port || (window.location.protocol === 'https:' ? '443' : '80'));
    const protocol = window.location.protocol;
    return `${protocol}//${host}:${port}`;
  };

  const playJoinSound = () => {
    const audio = new Audio('/sounds/join.wav'); // join.wav 파일 경로
    audio.play().catch(error => console.error('Join sound play error:', error)); // 오류 로그 추가
  };

  const playLeaveSound = () => {
    const audio = new Audio('/sounds/leave.wav'); // leave.wav 파일 경로
    audio.play().catch(error => console.error('Leave sound play error:', error)); // 오류 로그 추가
  };

  useEffect(() => {
    const url = getUrl();
    const newSocket = io(url);
    
    setSocket(newSocket);

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(stream => {
      localStreamRef.current.srcObject = stream;

      newSocket.emit('join', { roomId: roomId, name: name, auth: auth });

      newSocket.on('userId', async (userId) => {
        console.log(`[userId] userId: ${userId}`);
        setUserId(userId);
        setUsers(prevUsers => [...prevUsers, { userId, name, auth }]);
      });

      newSocket.on('roomFull', async (data) => {
        console.log(`[roomFull] data: ${JSON.stringify(data)}`);
        alert(data.message);
        navigate(-1);
      });

      newSocket.on('duplicateName', async (data) => {
        console.log(`[duplicateName] data: ${JSON.stringify(data)}`);
        alert(data.message);
        navigate(-1);
      });

      newSocket.on('allUsers', async (otherUsers) => {
        console.log(`[allUsers] otherUsers: ${JSON.stringify(otherUsers)}`);
        setUsers(prevUsers => [...prevUsers, ...otherUsers]);
        
        for (const user of otherUsers) {
          const peerConnection = createPeerConnection(newSocket, user.userId);
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);
          newSocket.emit('offer', { sdp: offer, to: user.userId });
          setUserAudioStatus(prevStatus => ({
            ...prevStatus,
            [user.userId]: true,
          }));
          setUserChatStatus(prevStatus => ({
            ...prevStatus,
            [user.userId]: true,
          }));
        }
      });

      newSocket.on('userJoined', async (data) => {
        console.log(`[userJoined] 데이터: ${JSON.stringify(data)}`);
        setUsers(prevUsers => [...prevUsers, { userId: data.userId, name: data.name }]);
        setUserAudioStatus(prevStatus => ({
          ...prevStatus,
          [data.userId]: true,
        }));
        setUserChatStatus(prevStatus => ({
          ...prevStatus,
          [data.userId]: true,
        }));
        // 사용자 입장 메시지 추가
        setMessages(prevMessages => [...prevMessages, { text: `${data.name}님이 입장했습니다.`, type: 'system', time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }]);
        playJoinSound(); // 사용자 입장 소리 재생
      });

      newSocket.on('offer', async (data) => {
        console.log(`[offer] 데이터: ${JSON.stringify(data)}`);
        const { sdp, from } = data;        
        const peerConnection = createPeerConnection(newSocket, from);
        await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        newSocket.emit('answer', { sdp: answer, to: from });
      });

      newSocket.on('answer', async (data) => {
        console.log(`[answer] 데이터: ${JSON.stringify(data)}`);
        const { sdp, from } = data;
        const peerConnection = peerConnections.current[from];
        await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
      });

      newSocket.on('candidate', async (data) => {
        console.log(`[candidate] 데이터: ${JSON.stringify(data)}`);
        const { candidate, from } = data;
        const peerConnection = peerConnections.current[from];
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      });

      newSocket.on('userDisconnected', (userId) => {
        console.log(`[userDisconnected] 유저아이디: ${userId}`);
        const peerConnection = peerConnections.current[userId];
        if (peerConnection) {
          peerConnection.close();
          delete peerConnections.current[userId];
          delete dataChannels.current[userId];
          delete userStreamRefs.current[userId];
          setUsers((prevUsers) => {
            const user = prevUsers.find((user) => user.userId === userId);
            const updatedUsers = prevUsers.filter((user) => user.userId !== userId);
            // 사용자 퇴장 메시지 추가
            if (user) {
              setMessages(prevMessages => [...prevMessages, { text: `${user.name}님이 나갔습니다.`, type: 'system', time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }]);
              playLeaveSound(); // 사용자 퇴장 소리 재생
            }
            return updatedUsers;
          });
        }
      });
    });

    // 컴포넌트 언마운트 시 실행될 로직
    return async () => {
      newSocket.close();
    };
  }, []);

  const createPeerConnection = (socket, userId) => {
    console.log(`createPeerConnection 유저아이디: ${userId}`);
    const peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    });

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit('candidate', { candidate: event.candidate, to: userId });
      }
    };

    peerConnection.ontrack = (event) => {
      userStreamRefs.current[userId].srcObject = event.streams[0];
    };

    peerConnection.ondatachannel = (event) => {
      console.log(`[ondatachannel] event: ${event}`)
      const dataChannel = event.channel;
      dataChannel.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setUserChatStatus((prevStatus) => {
          if (prevStatus[message.userId] !== false) {
            setMessages((prevMessages) => [...prevMessages, message]);
          }
          return prevStatus;
        });
      };
      dataChannels.current[userId] = dataChannel;
    };

    localStreamRef.current.srcObject.getTracks().forEach((track) => {
      peerConnection.addTrack(track, localStreamRef.current.srcObject);
    });

    peerConnections.current[userId] = peerConnection;

    const dataChannel = peerConnection.createDataChannel('chat');
    dataChannel.onopen = () => {
      console.log(`Data channel with user ${userId} is now open.`);
    };
    dataChannel.onclose = () => {
      console.log(`Data channel with user ${userId} has been closed.`);
    };
    dataChannel.onerror = (error) => {
      console.error(`Data channel error with user ${userId}: ${error}`);
    };
    dataChannel.onmessage = (event) => {
      console.log(`Data channel message event: ${event}`);
      const message = JSON.parse(event.data);
      setUserChatStatus((prevStatus) => {
        if (prevStatus[message.userId] !== false) {
          setMessages((prevMessages) => [...prevMessages, message]);
        }
        return prevStatus;
      });
    };
    dataChannels.current[userId] = dataChannel;

    return peerConnection;
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // 현재 시간 추가
      const message = { 
        user: name, 
        text: newMessage, 
        userId: userId, 
        type: 'user', // 메시지 타입 추가
        time: timestamp // 시간 추가
      };
      setMessages((prevMessages) => [...prevMessages, message]);
      for (const userId in dataChannels.current) {
        if (userChatStatus[userId] !== false) { // userChatStatus가 false가 아닌 경우에만 전송
          const dataChannel = dataChannels.current[userId];
          if (dataChannel.readyState === 'open') {
            dataChannel.send(JSON.stringify(message));
          } else {
            console.error(`Data channel for user ${userId} is not open. Current state: ${dataChannel.readyState}`);
          }
        }
      }
      setNewMessage('');
    }
  };
  
  const handleLeaveRoom = async () => {
    navigate("/");
  };

  const toggleAudio = (userId) => {
    console.log(`toggleAudio 호출 userId: ${userId}`);
    const audioElement = userStreamRefs.current[userId];    
    if (audioElement) {
      const isMuted = !audioElement.muted;
      audioElement.muted = isMuted;
      console.log(`userAudioStatus: ${JSON.stringify(userAudioStatus)}`);
      setUserAudioStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: !isMuted,
      }));
    } else{
      console.error(`audioElement is undefined`);
    }
  };

  const toggleMicrophone = () => {
    console.log(`toggleMicrophone 호출`);
    const audioTracks = localStreamRef.current.srcObject.getAudioTracks();
    if (audioTracks.length > 0) {
        const enabled = audioTracks[0].enabled;
        audioTracks[0].enabled = !enabled;
        setMicrophoneStatus(!enabled); // 마이크 상태 업데이트
    }
  };

  const toggleChat = (userId) => {
    console.log(`toggleChat 호출 userId: ${userId}`);
    console.log(`userChatStatus: ${JSON.stringify(userChatStatus)}`);
    setUserChatStatus((prevStatus) => ({
      ...prevStatus,
      [userId]: !prevStatus[userId],
    }));
    console.log(`userChatStatus: ${JSON.stringify(userChatStatus)}`);
  };

  const linkify = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const handleCopyInviteLink = () => {
    const inviteLink = `${window.location.origin}/invite/${roomId}`;
    navigator.clipboard.writeText(inviteLink)
      .then(() => {
        alert('초대 링크가 복사되었습니다.');
      })
      .catch(err => {
        console.error('초대 링크 복사 실패:', err);
      });
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus(); // 컴포넌트가 열릴 때 포커스 설정
  }, []);

  return (
    <>
      <div>
        <audio ref={localStreamRef} autoPlay muted />
        {users.map((user) => (
          <audio key={user.userId} ref={(el) => (userStreamRefs.current[user.userId] = el)} autoPlay />
        ))}
      </div>
      <div className="chatroom">
        <div className="header">
          <div className="container">
            <div className="titlebar">
              <div className="left">
                <a href="#" className="logo">
                  <img src="/resource/icon_logo_connn.svg" width="100" height="100" alt="SVG Image" />
                </a>
              </div>
              <div className="left-mobile">
                <button className="userinfo">참여자</button>
              </div>
              <div className="mid">
                {title}
              </div>
              <div className="right">
                  <button className="link-btn" onClick={handleCopyInviteLink}>
                    <img src="/resource/icon_link.svg" alt="초대링크 복사" style={{ width: '36px', height: '36px' }} />
                  </button>
                  <button className="leave-btn" onClick={handleLeaveRoom}>
                    <img src="/resource/icon_exit.svg" alt="나가기" style={{ width: '36px', height: '36px' }} />
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="body">
          <div className="ad_left">
            <a href="#">
              <img src="/ad/ad_home_001.png" alt="ad_vertical" />
            </a>
          </div>
          <div className="container">
            <div className="left">
              <div className="user-list">
                {users.map((user) => (
                  <div className="user" key={user.userId}>
                    <div className="auth">
                      {user.auth === 'manager' ? (
                        <img src="/resource/icon_crown.svg" alt="방장"/>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="name" style={{ fontWeight: user.userId === userId ? 'bold' : 'normal' }}>
                      {user.name}
                    </div>
                    {user.userId !== userId && ( // userId가 같지 않을 때만 표시
                    <div className="control">
                      <div className="mic">
                        <img src="/resource/icon_mic_on.svg" alt="마이크"/>
                      </div>
                      <button className="chat"
                        onClick={() => toggleChat(user.userId)}>
                        <img src={userChatStatus[user.userId] ? "/resource/icon_chat_on.svg" : "/resource/icon_chat_off.svg"} alt="채팅"/>
                      </button>
                      <button className="speaker"
                        onClick={() => toggleAudio(user.userId)}>
                        <img src={userAudioStatus[user.userId] ? "/resource/icon_volume.svg" : "/resource/icon_volume_off.svg"} alt="스피커"/>
                      </button>
                      {auth === 'manager' && ( // auth가 'manager'인 경우에만 표시
                        <button className="kick">
                          강퇴
                        </button>
                      )}
                    </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="right">
              <div className="message-list">
                {messages.map((message, index) => {
                  const showUser = index === 0 || messages[index - 1].userId !== message.userId; // 이전 메시지와 사용자 ID가 다를 때만 사용자 이름 표시
                  const showMargin = index === messages.length-1 || messages[index + 1].userId !== message.userId;
                return (
                  <div key={index} className="message" style={{ marginBottom: showMargin ? '20px' : '5px' }}>
                    {message.type === 'system' ? (
                      <div className="system-message">
                        <span className="text">{message.text}</span>
                        <span className="time">{message.time}</span>
                      </div>
                    ) : (
                      <div className={message.userId === userId ? "my-message" : "user-message"}>
                        {showUser && message.userId !== userId && ( // 사용자 ID가 다를 때만 표시
                          <span className="user">{message.user}</span>
                        )}
                        <span className="text">{linkify(message.text)}</span>
                        {index === messages.length - 1 || messages[index + 1].userId !== message.userId ? ( // 마지막 메시지이거나 다음 메시지의 사용자 ID가 다를 때만 시간 표시
                          <span className="time">{message.time}</span>
                        ) : null}
                      </div>
                    )}
                  </div>
                );
                })}
                <div ref={messagesEndRef} />
              </div>
              <div className="inputbar">
                <input
                  type='text'
                  placeholder='메시지를 입력하세요'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                  ref={inputRef} // ref 추가
                >
                </input>
                <button 
                  className='send' 
                  onClick={handleSendMessage}
                >
                  전송
                </button>
                <button className='mic'>
                  <img src="/resource/icon_mic_on.svg" alt="마이크"/>
                </button>
                <button className='speaker'>
                  <img src="/resource/icon_volume.svg" alt="헤드셋"/>
                </button>
              </div>
            </div>
          </div>
          <div className="ad_right">
            <a href="#">
              <img src="/ad/ad_home_001.png" alt="ad_vertical" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatRoom;