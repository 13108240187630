import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestAudioPermission } from '../utils/commonFunctions';

const EnterChatModal = ({ isOpen, setIsOpen, roomId, title }) => {
  const navigate = useNavigate();
  const nameInputRef = useRef(null);

  const handleEnterChat = async (name) => {
    if (!name || name.trim() === '') {
      alert('닉네임은 필수값입니다.');
      return;
    }
    const permissionGranted = await requestAudioPermission();
    if (!permissionGranted) return;
    const auth = 'guest';
    navigate(`/chatroom`, { state: { roomId, name, auth, title } });
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [setIsOpen]);

  return (
    <div className={`modal ${isOpen ? 'show' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={() => setIsOpen(false)}>&times;</span>
        <form className='enter-chat-room' onSubmit={(e) => {
          e.preventDefault();
          const name = e.target.elements.name.value;
          handleEnterChat(name);
        }}>
          <div className="form-group">
            <label>닉네임을 입력하세요 *</label>
            <input 
              type="text" 
              name="name" 
              required
              maxLength="12"
              ref={nameInputRef}
              className="form-control"
            />
          </div>
          <button type="submit" className="submit-button" style={{ 
          }}>입장</button>
        </form>
      </div>
    </div>
  );
};

export default EnterChatModal;