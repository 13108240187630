import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnterChatModal from './enterChatModal';
import { getApiUrl } from '../utils/commonFunctions';

function InvitePage() {
  const { roomId } = useParams();
  const numericRoomId = parseInt(roomId, 10); // roomId를 숫자 형식으로 변환
  const [isModalOpen, setIsModalOpen] = useState(false); // 초기값을 false로 설정
  const [isValidRoom, setIsValidRoom] = useState(false); // 유효한 채팅방인지 확인하는 상태 추가

  const fetchCheckRooms = async (id) => {
    const url = getApiUrl(`/api/chatRooms/${id}`);
    console.log(`fetchCheckRooms url: ${url}`);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response;
    } catch (error) {
      throw new Error(`채팅방 목록을 불러오는데 실패했습니다: ${error}`);
    }
  };

  useEffect(() => {
    // roomId의 유효성을 체크하는 함수
    const checkRoomValidity = async () => {
      try {
        const response = await fetchCheckRooms(numericRoomId);
        if (response.ok) {
          setIsValidRoom(true);
          setIsModalOpen(true); // 유효한 경우에만 모달을 띄움
        } else {
          setIsValidRoom(false);
        }
      } catch (error) {
        console.error('채팅방이 유효하지 않습니다:', error);
        setIsValidRoom(false);
      }
    };

    checkRoomValidity();
  }, [numericRoomId]);

  return (
    <div>
      {isValidRoom ? (
        <EnterChatModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} roomId={numericRoomId} />
      ) : (
        <p>유효하지 않은 채팅방입니다.</p>
      )}
    </div>
  );
}

export default InvitePage;