import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './components/mainPage';
import ChatRoom from './components/chatRoom';
import InvitePage from './components/invitePage';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="app">
      <Helmet>
        <title>connn :: 모두의 콘</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="/ui.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0" />
        <link rel="stylesheet" href="/resource/fonticon/style.css" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/chatroom" element={<ChatRoom />} />
          <Route path="/invite/:roomId" element={<InvitePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;