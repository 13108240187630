import React , { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import EnterChatModal from './enterChatModal';
import { getApiUrl } from '../utils/commonFunctions';
import CreateChatModal from './createChatModal';

const fetchChatRooms = async () => {
  const url = getApiUrl('/api/chatRooms');
  console.log(`fetchChatRooms url: ${url}`);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`채팅방 목록을 불러오는데 실패했습니다: ${error}`);
  }
};

// 카테고리에 따라 채팅 목록을 가져오는 함수
const fetchSearchChatRooms = async (categoryId, searchQuery) => {
  const url = getApiUrl('/api/chatRooms/search');
  console.log(`fetchSearchChatRooms url: ${url}`);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ categoryId, searchQuery }) // userId와 roomId를 JSON 형태로 전송
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('채팅 목록을 불러오는데 실패했습니다:', error);
  }
};

const formatRelativeTime = (dateString) => {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now - past) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}초 전`;
  } else if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)}분 전`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)}시간 전`;
  } else {
    return `${Math.floor(diffInSeconds / 86400)}일 전`;
  }
};

const MainPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(0); // 초기값을 0으로 설정
  const [chatList, setChatList] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // CreateChatRoom 모달 상태
  const [isEnterModalOpen, setIsEnterModalOpen] = useState(false); // EnterChatModal 모달 상태
  const [searchQuery, setSearchQuery] = useState(null);
  const categoryIds = [0, 1, 2, 3, 4, 5, 6]; // 0부터 6까지의 카테고리 ID
  const [selectedRoomId, setSelectedRoomId] = useState(null); // selectedRoomId 상태 추가
  const [selectedRoomTitle, setSelectedRoomTitle] = useState(null); // selectedRoomTitle 상태 추가

  // 카테고리가 변경될 때마다 채팅 목록 갱신
  useEffect(() => {
    console.log(`useEffect() refreshChatList`);
    const refreshChatList = async () => {
      const data = await fetchSearchChatRooms(selectedCategory, searchQuery);
      setChatList(data);
    };
    refreshChatList();
  }, [selectedCategory, searchQuery]);

  useEffect(() => {
    console.log(`useEffect() init`);
    const init = async () => {
      const data = await fetchChatRooms();
      setChatList(data);
    };

    init();
  }, []);

  const getCategoryIcon = (categoryId) => {
    switch (categoryId) {
      case 0:
        return <span className="material-symbols-outlined fonticon">interests</span>;
      case 1:
        return <img src="/resource/icon_logo_lol.png" alt="리그 오브 레전드" />;
      case 2:
        return <img src="/resource/icon_logo_battle.png" alt="배틀 그라운드" />;
      case 3:
        return <img src="/resource/icon_logo_valo.png" alt="발로란트" />;
      case 4:
        return <img src="/resource/icon_logo_over.png" alt="오버워치" />;
      case 5:
        return <span className="material-symbols-outlined fonticon">area_chart</span>;
      case 6:
        return <span className="material-symbols-outlined fonticon">favorite</span>;
      default:
        return null;
    }
  };

  const getChatRoomIcon = (categoryId, categoryName) => {
    switch (categoryId) {
      case 0:
        return <span className="material-symbols-outlined fonticon" data-info="interests">interests</span>;
      case 1:
        return <img src="/resource/icon_logo_lol.png" data-info="pic" alt="리그 오브 레전드" />;
      case 2:
        return <img src="/resource/icon_logo_battle.png" data-info="pic" alt="배틀 그라운드" />;
      case 3:
        return <img src="/resource/icon_logo_valo.png" data-info="pic" alt="발로란트" />;
      case 4:
        return <img src="/resource/icon_logo_over.png" data-info="pic" alt="오버워치" />;
      case 5:
        return <span className="material-symbols-outlined fonticon" data-info="stock">area_chart</span>;
      case 6:
        return <span className="material-symbols-outlined fonticon" data-info="etc">favorite</span>;
      default:
        return null;
    }
  };

  const getCategoryTitle = (categoryId) => {
    switch (categoryId) {
      case 0:
        return "all";
      case 1:
        return "리그 오브 레전드";
      case 2:
        return "배틀 그라운드";
      case 3:
        return "발로란트";
      case 4:
        return "오버워치";
      case 5:
        return "주식 & 코인";
      case 6:
        return "잡담";
      default:
        return "";
    }
  };

  return (
    <>
      <body>
        <div className="header">
          <div className="container">
            <div className="topbar">            
              <a href="#" className="logo">
                <img src="/resource/icon_logo_connn.svg" width="100" height="100" alt="SVG Image" />
              </a>
              <div className="categorybar">
                <div className="type_button" >
                  {categoryIds.map((categoryId) => (
                    <button 
                      key={categoryId} 
                      className={selectedCategory === categoryId ? 'active' : ''} 
                      title={getCategoryTitle(categoryId)}
                      onClick={() => setSelectedCategory(categoryId)}
                    >
                      {getCategoryIcon(categoryId)}
                    </button>
                  ))}
                </div>
                <select name="" id="" className="type_select" onChange={(e) => setSelectedCategory(Number(e.target.value))}>
                  {categoryIds.map((categoryId) => (
                    <option key={categoryId} value={categoryId}>{getCategoryTitle(categoryId)}</option>
                  ))}
                </select>
              </div> 
            </div>
            <div className="searchbar">
              <div className="g_input">                        
                <span className="material-symbols-outlined fonticon">search</span>
                <input 
                  type="text" 
                  autoFocus 
                  placeholder="방제목 입력" 
                  onChange={_.debounce((e) => setSearchQuery(e.target.value), 300)} // 디바운스 적용
                />
              </div>
            </div>
          </div>
        </div>
        <div className="body" >
          <div className="ad_left">
            <a href="#">
              <img src="/ad/ad_home_001.png" alt="ad_vertical" />
            </a>
          </div>
          <div className="container">
            <button className="add" onClick={() => setIsCreateModalOpen(true)}>
              <span className="material-symbols-outlined fonticon">add</span>
            </button>
            {isCreateModalOpen && <CreateChatModal setIsModalOpen={setIsCreateModalOpen} />}
            <div className="room_list">
              {chatList.map((chat) => (
                <div 
                  className="room_unit" 
                  key={chat.roomId}
                  onDoubleClick={() => {
                    setSelectedRoomId(chat.roomId); // selectedRoomId 설정
                    setSelectedRoomTitle(chat.title); // selectedRoomTitle 설정
                    setIsEnterModalOpen(true);
                  }} // 더블클릭 이벤트 수정
                >
                  <div className="g_top">
                    <div className="room_category">
                      {getChatRoomIcon(chat.categoryId, chat.categoryName)}
                    </div>   
                    <span className="room_title">
                      {chat.title}
                    </span>  
                  </div>   
                  <div className="g_bottom">  
                    <span className="room_time">
                      {formatRelativeTime(chat.updatedAt)}
                    </span>
                    <span className="room_num">
                      <span className="in">{chat.userCount}</span>
                      /
                      <span className="max">{chat.totalMembers}</span>
                    </span>
                    <div className="room_nickname">
                      <span className="material-symbols-outlined fonticon">graphic_eq</span>
                      <span className="nickname">{chat.userList[0]?.name}</span>                            
                    </div>
                  </div>
                </div>
              ))}
              {isEnterModalOpen && <EnterChatModal isOpen={isEnterModalOpen} setIsOpen={setIsEnterModalOpen} roomId={selectedRoomId} title={selectedRoomTitle} />}
            </div>
            <footer>
              <span className="material-symbols-outlined">chat_info</span>
              <span>문의사항</span>
              <span>ttest@naver.com</span>
            </footer>
            <div className="ad_bottom">
              <a href="#">
                <img src="/ad/ad_mobile_bot_001.png" alt="ad_horizontal" />
              </a>
            </div>
          </div>
          <div className="ad_right">
            <a href="#">
              <img src="/ad/ad_home_001.png" alt="ad_vertical" />
            </a>
          </div>
        </div>
      </body>
    </>
  )
}

export default MainPage;