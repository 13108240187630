export const requestAudioPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'denied') {
        alert('마이크 권한이 필요합니다. 브라우저 설정에서 마이크 권한을 허용해주세요.');
        return false;
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('음성 권한이 허용되었습니다.');
      return true;
    } catch (error) {
      console.error('음성 권한을 요청하는데 실패했습니다:', error);
      alert('음성 권한이 필요합니다.');
      return false;
    }
  };

  export const getApiUrl = (path) => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const host = isDevelopment ? process.env.REACT_APP_API_HOST : window.location.hostname;
    const port = isDevelopment ? process.env.REACT_APP_API_PORT : (window.location.port || (window.location.protocol === 'https:' ? '443' : '80'));
    const protocol = window.location.protocol;
    return `${protocol}//${host}:${port}${path}`;
  };